.App {
  text-align: center;
}

.header-font-white {
  color: white;
}

#navbar {
  position: sticky;
  zIndex: 1003;
  backgroundColor: #16191f;
}

/*This CSS hide the signUp tab*/
.amplify-tabs {
  display: none;
}

.awsui-context-top-navigation img {
  min-height: 3.5em;
}

[data-amplify-authenticator] {
  padding-top: 5em;
}

[data-amplify-authenticator]::before {
  content: "RetInSight Upload Tool";
  font-size:var(--amplify-components-heading-3-font-size);
  margin: 0 auto;
  padding-bottom: 1em;
  color: white;
  /* color: white; */
}
[data-amplify-authenticator]::after {
  content: url("../public/RET-Logo\ with\ claim_RGB_BW_NEGATIV-.svg");
  margin: 0 auto;
  padding-top: 1.5em;
}

[data-amplify-router] {
  border-radius: 8px;
}

body {
  background-color: #16191f;
}

/* This adjust the SignIn font size */
:root{
--amplify-components-fieldcontrol-small-font-size: 1rem;
--amplify-components-button-font-size: 1rem;
--amplify-components-button-primary-background-color: #4f46e5;
--amplify-components-fieldcontrol-font-size: 1rem;
--amplify-components-tabs-item-font-size: 1rem;

--amplify-components-authenticator-router-border-color: rgb(229, 231, 235);

--amplify-colors-indigo-10: hsl(243, 75%, 95%);
--amplify-colors-indigo-20: hsl(243, 75%, 85%);
--amplify-colors-indigo-40: hsl(243, 75%, 75%);
--amplify-colors-indigo-60: hsl(243, 75%, 50%);
--amplify-colors-indigo-80: hsl(243, 75%, 30%);
--amplify-colors-indigo-90: hsl(243, 75%, 20%);
--amplify-colors-indigo-100: hsl(243, 75%, 15%);

--amplify-colors-brand-primary-10: var(--amplify-colors-indigo-10);
--amplify-colors-brand-primary-20: var(--amplify-colors-indigo-20);
--amplify-colors-brand-primary-40: var(--amplify-colors-indigo-40);
--amplify-colors-brand-primary-60: var(--amplify-colors-indigo-60);
--amplify-colors-brand-primary-80: var(--amplify-colors-indigo-80);
--amplify-colors-brand-primary-90: var(--amplify-colors-indigo-90);
--amplify-colors-brand-primary-100: var(--amplify-colors-indigo-100);

--amplify-colors-brand-secondary-10: var(--amplify-colors-indigo-10);
--amplify-colors-brand-secondary-20: var(--amplify-colors-indigo-20);
--amplify-colors-brand-secondary-40: var(--amplify-colors-indigo-40);
--amplify-colors-brand-secondary-60: var(--amplify-colors-indigo-60);
--amplify-colors-brand-secondary-80: var(--amplify-colors-indigo-80);
--amplify-colors-brand-secondary-90: var(--amplify-colors-indigo-90);
--amplify-colors-brand-secondary-100: var(--amplify-colors-indigo-90);
}

